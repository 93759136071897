import logo from './logo.svg';
import './App.css';
import { LandingPage } from './pages/landing-page/LandingPage';

const App = () => {
  return (
    <LandingPage />
  );
}

export default App;
