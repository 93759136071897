import React from "react";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { BannerImage } from '../../components/banner-image/BannerImage';
import { Countdown } from '../../components/countdown/Countdown';


export const LandingPage = () => {
  
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={12}>
        <BannerImage />
      </Grid>
      <Grid item xs={12}>
        <Countdown />
      </Grid>

  </Grid> 
  );
}
