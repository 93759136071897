import React from "react";
import Box from '@material-ui/core/Box';
import "./BannerImage.css";

export const BannerImage = () => {
  
  return (
    <div className="BannerImage">
        <Box pt={4}>
        <img src="nuclei-coming-soon.png" className="NucleiAndDreams" />
        </Box>
    </div>
  );
};
