import React from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import "./Countdown.css";

const minuteSeconds = 60;
const hourSeconds = 3600;
const daySeconds = 86400;

const timerProps = {
  isPlaying: true,
  size: 120,
  strokeWidth: 2,
  trailColor: "#000000"
};

const renderTime = (dimension, time) => {
  return (
    <div className="time-wrapper">
      <div className="time"><strong>{time}</strong></div>
      <div className="dimension">{dimension.toUpperCase()}</div>
    </div>
  );
};

const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0;
const getTimeDays = (time) => (time / daySeconds) | 0;

export const Countdown = () => {
  const startTime = Date.now() / 1000; // use UNIX timestamp in seconds

  const endTime = Date.parse("2023-06-30") / 1000; // startTime + 243248; // use UNIX timestamp in seconds

  const remainingTime = endTime - startTime;
  const days = Math.ceil(remainingTime / daySeconds);
  const daysDuration = days * daySeconds;

  const colorList = [
    ["#3d1f76"]
  ];

  return (
    <div className="Countdown">

          <Box p={2} mb={8}>
            <CountdownCircleTimer
                {...timerProps}
                colors={colorList}
                duration={daysDuration}
                initialRemainingTime={remainingTime}
            >
                {({ elapsedTime }) =>
                  renderTime("days", getTimeDays(daysDuration - elapsedTime))
                }
            </CountdownCircleTimer>
        </Box>
        
      <Box p={2} mb={8}>
      <CountdownCircleTimer
        {...timerProps}
        colors={colorList}
        duration={daySeconds}
        initialRemainingTime={remainingTime % daySeconds}
        onComplete={(totalElapsedTime) => [
          remainingTime - totalElapsedTime > hourSeconds
        ]}
      >
        {({ elapsedTime }) =>
          renderTime("hours", getTimeHours(daySeconds - elapsedTime))
        }
      </CountdownCircleTimer>
      </Box>
      
      
      
    </div>
  );
}
